import QuestionItem from "../QuestionItem/QuestionItem";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { httpsCallable } from 'firebase/functions';
import { useState } from "react"
import FirebaseConnect from "../../FirebaseConnect";
import progress1 from '../../assets/progress1.png';
import progress2 from '../../assets/progress2.png';
import progress3 from '../../assets/progress3.png';
import progress4 from '../../assets/progress4.png';
import progress5 from '../../assets/progress5.png';
import splash from '../../assets/splash.png';
import { initializeAnalytics, logEvent } from "firebase/analytics";



const ENTER_PAGE = 0;
const INPUT_PAGE = 1;
const SUCCESS_PAGE = 2;

const Pages = ['Fund Background','Fundraise Information','Fund Documents',
              'Strategy Information', 'Fund Terms', 'ILPA Operational Due Diligence', 'Service Providers'];

function DocGather() {

  const [step, updateStep] = useState(ENTER_PAGE);
  const [showPassword, setShowPassword] = useState(false); // state to toggle password visibility on login screen
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [credentials, setCredentials] = useState(['', '']); // state to store credentials, [id, accesskey]
  const [sfIds, setSfIds] = useState({ // salesforce record id's returned from getDocGatherInputs, corresponding to firm, fund, and diligence records
    "firmId":"", 
    "fundId":"", 
    "diligenceId":""
  });
  const [pageCounter, updatePage] = useState(0); // page counter initiated to 0
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState([]) // dictionary
  const [fileMetaData, setFileMetaData] = useState({
    "fundName":"",
    "parentFolder":""
  });

  //state to store saveBtnProps, [text, color] where text is the text displayed on the button (ex: Save, Saving, Saved)
  // potential todo: change color of button as a prop
  const [saveBtnProps, setSaveBtnProps] = useState(['Save', '']); 

  // function to handle updating credentials state from input boxes on login screen
  const handleLoginInput = (e) => {
    if (e.target.id === 'firmIdInput') {
      let value = e.target.value;
      credentials[0] = value;
      setCredentials(credentials);
    }
    else if (e.target.id === 'firmPasswordInput'){
      let value = e.target.value;
      credentials[1] = value;
      setCredentials(credentials);
    }
    console.log(credentials);
  }

  // function to handle updating dictionary from form
  const handleChange = async (e, driveLink) => {
    const type = e.target.type;

    let id;
    if (type === 'file') {
      // The form ID's look like 'form-11' for example
      // Need to remove the 'form-' so we can index into the number
      const formId = e.target.form.id;
      id = Number(formId.substring(5));
      console.log("id", id)
    } else {
      id = e.target.id
    }

    // Get the proper inputValue based on type of question (select-multiple, file, text, etc)
    let value;
    if (type === "select-multiple") {
      value = Array.from(e.target.selectedOptions, option => option.value)
    } else if (type === "file") {
      value = driveLink;
    }
    else {
      value = e.target.value;
    }

    data[id].inputValue = value;
    setData(data);
    console.log("newData", data);
    setSaveBtnProps(['Save','']);
    //console.log(data);
  }

  switch (step) {
    case ENTER_PAGE: return DisplayLogin(updateStep, handleLoginInput, credentials, errors, setErrors, isLoggingIn, setLoggingIn, setSfIds, data, setData, showPassword, setShowPassword, setFileMetaData);
    case INPUT_PAGE: return DisplayFormInput(pageCounter, updatePage, updateStep, data, handleChange, errors, setErrors, saveBtnProps, setSaveBtnProps, sfIds, fileMetaData);
    case SUCCESS_PAGE: return DisplaySuccess();
    default: return DisplayLogin(updateStep);
  }

}

// Function to call when 'save' is clicked
function sendSaveResponse(sfIds, data, setSaveBtnProps) {
  const sendDocGatherSave = httpsCallable(FirebaseConnect.functions, 'sendDocGatherSave');
  console.log(sfIds);
  setSaveBtnProps(['Saving...', 'Red']);
  sendDocGatherSave( {"ids": sfIds, "dict": data} ).then( (response) => {
    if (response.data.status !== 200) {
      console.log(response);
      setSaveBtnProps(['Error saving', 'Red']);
    }
    else {
      console.log(response);
      setSaveBtnProps(['Saved', '']);
    }
  }).catch( (error) => { // if post request fails (endpoint not reached/some other error)
    setSaveBtnProps(['Error failed to save', 'Red']);
    console.log("Error:",error);
  });
}

/*
* Form input pages 
* @param pageCounter - state for current page
* @param updatePage - state function to update pageCounter
* @param updateStep - state function to change step (enter, input, success)
*/
function DisplayFormInput(pageCounter, updatePage, updateStep, data, handleChange, errors, setErrors, saveBtnProps, setSaveBtnProps, sfIds, fileMetaData) {

  
  const handleSave = (e) => {
    sendSaveResponse(sfIds, data, setSaveBtnProps)
  }

  // Progress Bar
  let imgSource = "";
  if(pageCounter === 0) {
    imgSource = progress1;
  } else if (pageCounter === 1) {
    imgSource = progress2;
  } else if (pageCounter === 2) {
    imgSource = progress3;
  } else {
    imgSource = progress4;
  }


  // building question components
  const result = [];
  result.push(<h1 key='Header'>{Pages[pageCounter]}</h1>);
  for (let i = 0; i < data.length; i++) {
    if (data[i].page === Pages[pageCounter]) {
      result.push(<QuestionItem key={i} id={i} question={data[i]} handleChange={(handleChange)} fileMetaData={fileMetaData} />);
      
    }
  }

  // buttons depending on if first, middle, or last pages
  const buttons = [];
  if (pageCounter !== 0) buttons.push(<button key='PrevBtn' className='formButton' onClick={() => prevPage(pageCounter, updatePage, setErrors)} >Previous Page</button>);
  if (pageCounter === Pages.length - 1) buttons.push(<button key='SubmitBtn' className='formButton' onClick={() => submit(updateStep, data, result, setErrors) } >Submit</button>);
  else buttons.push(<button type='submit' key='NextBtn' className='formButton' onClick={() => nextPage(pageCounter, updatePage, data, result, setErrors)} >Next Page</button>);

  //page
  return (
    <>
      <div className="progress-bar">
        <img draggable="false" src={imgSource} id='progress-img'/>
      </div>
      <div className="form-box-container">
        <div className='formBox'>
        
          {result}
          <div className="buttons-and-errors">
            <div className="buttons">   
              {buttons}
              <button className='formButton' color={saveBtnProps[1]} onClick={(e) => handleSave(e)} >{saveBtnProps[0]}</button>
            </div>
            <div>
            
            </div>
            <div className="form-errors">
              <ul className="form-bullets">
                {errors.map(error => <li key={error}>{error}</li>)}
              </ul>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )


}

/*
* Beginning start page
* @param updateStep - state function to change step (enter, input, success)
*/
function DisplayLogin(updateStep, handleLoginInput, credentials, errors, setErrors, isLoggingIn, setLoggingIn, setSfIds, data, setData, showPassword, setShowPassword, setFileMetaData) {

  const togglePassword = () => {
    setShowPassword(!showPassword);
  }
  return (
    <>
      <div className="formEnterPage">
        <div className='formEnter'>
          <div className="loginEnterBody">
          <h2>Login</h2>
          <p>ID</p>
          <input id='firmIdInput' class='loginInputs2' onChange={handleLoginInput} />
          <p>Access Key</p>
          <input id='firmPasswordInput' class='loginInputs' type={showPassword ? "text" : "password"} onChange={handleLoginInput} />
          <button className="visibilityToggleButton" onClick={togglePassword}> 
          {showPassword ? <FaEyeSlash style={{ color: '#cfd1d4' }} /> : <FaEye style={{ color: '#cfd1d4' }} />}</button>
          </div>
          <div className="formEnterButton">
            <button className='displayButton' onClick={() => { handleLogin(credentials, updateStep, setErrors, setLoggingIn, setSfIds, data, setData, setFileMetaData)} } >Login</button>
          </div>
          {isLoggingIn ? (<div className="loading-circle"></div>) : (<></>) }
          <div className="form-errors">
              <ul className="form-bullets">
                
                {errors.map(error => <li key={error}>{error}</li>)}
              </ul>
          </div>
        </div>
      </div>
    
    </>
  )
}


// function to call when 'Login' is clicked, handles checking auth with firebase endpoint as 
// well as updating dictionary and updating step to render the form pages
function handleLogin(credentials, updateStep, setErrors, setLoggingIn, setSfIds, data, setData, setFileMetaData) {
  if (credentials[0] === "") {
    setErrors(["Please enter your firm ID"]);
    return;
  }
  else if (credentials[1] === "") {
    setErrors(["Please enter your access key"]);
    return;
  }
  console.log("Trying to login with " + credentials);
  setErrors([])
  setLoggingIn(true);
  FirebaseConnect.initInstance();
  const getDocGatherId = httpsCallable(FirebaseConnect.functions, 'getDocGatherId');
  getDocGatherId({'id':credentials[0],
                  'accesskey':credentials[1]})
  .then((response) => {
    if (response.data.status === 200) {
      console.log(response);
      
      const getInputs = httpsCallable(FirebaseConnect.functions, 'getDocGatherInputs2');
      getInputs( { 'id': response.data.id }).then( (inputs) => {
        console.log("Now we have inputs:");
        console.log(inputs);
        setSfIds({
          "firmId":inputs.data.firmId, 
          "fundId": inputs.data.fundId, 
          "diligenceId": inputs.data.diligenceId
        });
        setFileMetaData({
          "fundName": inputs.data.fund.Name,
          "parentFolder": inputs.data.diligence.Google_Drive_Folder_ID__c
        });
        setData(inputs.data.dict);
        console.log("Now data is:");
        console.log(data);
        setErrors([]);
        updateStep(INPUT_PAGE);
      });
      
    }
    else {
      console.log(response);
      setLoggingIn(false);
      setErrors(['Invalid ID or Access Token']);
    }
  })
  
}

/*
* End success page
*/
function DisplaySuccess() {
  return (
    <>
      <div className="progress-bar">
        <img src={progress5} id='progress-img' />
      </div>
      <div className="formEnterPage">
        <div className='formEnter'>
          <div className="formEnterBody">
            <div className="formEnterText">
              <h1>Thank you for registering!</h1>
              <p>We will be in touch.</p>
              <p>In the meantime, please feel free to review our onboarding guide below.</p>
            </div>
            <div className="formEnterImg">
              <img src={splash} id='splash-img' />
            </div>
          </div>
          <div className="formEnterButton">
            <button className='displayButton'>Go to onboarding guide</button>
          </div>
        </div>
      </div>
    </>
  )
}

/*
* Button onClick action to go next page in form (update pageCounter state)
*/
function nextPage(pageCounter, updatePage, data, result, setErrors) {
  setErrors([]);
  if (pageCounter >= Pages.length - 1) return;
  let canProceed = validatePage(data, result, setErrors);

  if (canProceed === true) {
    updatePage(pageCounter + 1);
    setErrors([]);
  } else {
    setErrors(prevErrors => [...prevErrors, "Please fill in all required fields"]);
  }
}

/*
* Button onClick action to go previous page in form (update pageCounter state)
*/
function prevPage(pageCounter, updatePage, setErrors) {
  if (pageCounter <= 0) return;
  setErrors([])
  updatePage(pageCounter-1);
}

/*
* todo: update this 
*/
function submit(updateStep, data, result, setErrors) {
  let canProceed = validatePage(data, result, setErrors);;

  if (canProceed === true) {
    /*
    setErrors(['Submitting...']);
    FirebaseConnect.initInstance();
    console.log(data);
    const postSubmit = httpsCallable(FirebaseConnect.functions, 'postRegistration'); // change to 'postRegistration' function to write to salesforce
    postSubmit(data)   
    .then(response => {
      setErrors([]);
      if (response.data.status !== 200) { // if we receive some sort of status back from firebase but bad request
        setErrors(["Failed to submit", response.data.message]); 
        console.log("Error:",response);
      }
      else {
        setErrors([]);
        console.log("Response:", response);
        updateStep(SUCCESS_PAGE);
      }

    })
    .catch( (error) => { // if post request fails (endpoint not reached/some other error)
      setErrors(["Failed to submit", "Please try again"]);
      console.log("Error:",error);
    });
    */
  } else {
    setErrors(["Please fill in all required fields"]);
  }
  
}

/*
* Validates if the email is a string or a subset of ASCII characters that are separated into two parts by “@” symbol
* @param inputEmail - email inputted by the user 
*/
function validateEmail(inputEmail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputEmail)) {
    return true;
  } else {
    return false;
  }
}

/*
* Validates all required fields are filled out before moving on to next page
* @param data - state for all questions
* @param result - array that holds the questions on the current page
* @param setErrors - state function to update errors to display
*/
function validatePage(data, result, setErrors) {
  let canProceed = true;
  clearErrorStyling(result);

  // Loop through result starting at 1 to skip the Header
  for (let i = 1; i < result.length; i++) {
    let id = result[i].props.id;
    // Check if required inputs are emtpy
    if (data[id].required === true && (data[id].inputValue === "" || data[id].inputValue === [] || data[id].inputValue === null)) {
      canProceed = false;
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.add("red-outline");
      } else {
        id = `form-${result[i].props.id}`;
        document.getElementById(id).classList.add("red-outline");
      }
      
    }
    // Check if the email input is valid
    if (data[id].inputType === "email" && !validateEmail(data[id].inputValue)) {
      canProceed = false;
      document.getElementById(id).classList.add("red-outline");
      setErrors(prevErrors => [...prevErrors, "Invalid email"]);
    }
  }
  return canProceed;
}

/*
* Clears error styling when moving to next page
* @param result - array that holds the questions on the current page
*/
function clearErrorStyling(result) {
  for (let i = 1; i < result.length; i++) {
    let id = result[i].props.id;
    if (document.getElementById(id) !== null) {
      document.getElementById(id).classList.remove("red-outline");
    } else {
      id = `form-${result[i].props.id}`;
      document.getElementById(id).classList.remove("red-outline");
    }
    
  }
}
// moved to submit function
/*
function postSubmitRequest(dictionary) {
  FirebaseConnect.initInstance();
  console.log(dictionary);
  const postSubmit = httpsCallable(FirebaseConnect.functions, 'sampleSubmitRequest');
  postSubmit(dictionary)   
  .then(response => console.log(response))
  .catch(error => console.log("Error code:", error));

}*/


export default DocGather;

import QuestionItem from "../QuestionItem/QuestionItem";
import { httpsCallable } from 'firebase/functions';
import { useState } from "react"
import FirebaseConnect from "../../FirebaseConnect";
import './Registration.css'
import progress1 from '../../assets/progress1.png';
import progress2 from '../../assets/progress2.png';
import progress3 from '../../assets/progress3.png';
import progress4 from '../../assets/progress4.png';
import progress5 from '../../assets/progress5.png';
import splash from '../../assets/splash.png';

const ENTER_PAGE = 0;
const INPUT_PAGE = 1;
const SUCCESS_PAGE = 2;

const Pages = ['Contact Info', 'Fund Info', 'Fund Info 2', 'Survey Info'];

function MainPage() {

  const [step, updateStep] = useState(ENTER_PAGE);

  const [pageCounter, updatePage] = useState(0); // page counter initiated to 0
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState([
    {
      question: "First Name",
      page: "Contact Info",
      inputType: "text",
      inputOptions: "",
      exampleText: "John",
      salesforceField: "FirstName",
      salesforceObject: "Contact",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "Last Name",
      page: "Contact Info",
      inputType: "text",
      inputOptions: "",
      exampleText: "Smith",
      salesforceField: "LastName",
      salesforceObject: "Contact",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "Email Address",
      page: "Contact Info",
      inputType: "email",
      inputOptions: "",
      exampleText: "john.smith@reverevc.com",
      salesforceField: "Email",
      salesforceObject: "Contact",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "Title",
      page: "Contact Info",
      inputType: "text",
      inputOptions: "",
      exampleText: "General Partner",
      salesforceField: "Title",
      salesforceObject: "Contact",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "How did you hear about Revere?",
      page: "Contact Info",
      inputType: "textarea",
      inputOptions: "",
      exampleText: "",
      salesforceField: "",
      salesforceObject: "",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "What is the name of your Firm?",
      page: "Fund Info",
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Firm__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "What is your firm's website (if you have one)?",
      page: "Fund Info",
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Website__c",
      salesforceObject: "Firm__c",
      required: true,
      inputValue: "",
      infoText: "",
    },
    {
      question: "What is the name of your current Fund?",
      page: "Fund Info",
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "What is your target fund size?",
      page: "Fund Info",
      inputType: "number",
      inputOptions: "",
      exampleText: "Enter $20,000,000 as 20000000",
      salesforceField: "Fund_Size__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "How much capital have you closed?",
      page: "Fund Info",
      inputType: "number",
      inputOptions: "",
      exampleText: "Enter $10,000,000 as 10000000",
      salesforceField: "Amount_Closed__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "What is the minimum check size for LPs?",
      page: "Fund Info",
      inputType: "number",
      inputOptions: "",
      exampleText: "Enter $100,000 as 100000",
      salesforceField: "Minimum_Check_Size__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "Are you actively Fundraising?",
      page: "Fund Info",
      inputType: "Single Select",
      inputOptions: ["Yes", "No"],
      exampleText: "",
      salesforceField: "Actively_Fundraising__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: "When is/was your first close?",
      page: "Fund Info 2",
      inputType: "date",
      inputOptions: "",
      exampleText: "",
      salesforceField: "First_Close__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: 'When is your next close?',
      page: 'Fund Info 2',
      inputType: 'date',
      inputOptions: '',
      exampleText: '',
      salesforceField: "Next_Close__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: 'When is your final close?',
      page: 'Fund Info 2',
      inputType: 'date',
      inputOptions: '',
      exampleText: '',
      salesforceField: "Final_Close__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: 'What is your primary sector focus?',
      page: 'Fund Info 2',
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: 'In which stages do you primarily invest?',
      page: 'Fund Info 2',
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: 'What are a few Industry Keywords that describe your thesis area?',
      page: 'Fund Info 2',
      inputType: 'textarea',
      inputOptions: '',
      exampleText: 'ex FinTech, Payments, B2B Commerce',
      salesforceField: "Industry_Keywords__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: 'Why are you interested in working with Revere?',
      page: 'Survey Info',
      inputType: 'Multi Select',
      inputOptions: ['tbd'],
      exampleText: '',
      salesforceField: '',
      salesforceObject: "",
      required: true,
      inputValue: "",
      infoText: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 'Survey Info',
      inputType: 'Single Select',
      inputOptions: ["Yes", "No"],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Firm__c",
      required: false,
      inputValue: "",
      infoText: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 'Survey Info',
      inputType: 'Single Select',
      inputOptions: ["Yes", "No"],
      exampleText: '',
      salesforceField: "Female_GP__c",
      salesforceObject: "Firm__c",
      required: false,
      inputValue: "",
      infoText: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 'Survey Info',
      inputType: 'Single Select',
      inputOptions: ["Yes", "No"],
      exampleText: '',
      salesforceField: '',
      salesforceObject: "",
      required: true,
      inputValue: "",
      infoText: ""
    }
  ])

  const handleChange = (e) => {
    const type = e.target.type;
    let value;
    if (type === "select-multiple") {
      value = Array.from(e.target.selectedOptions, option => option.value)
    } else {
      value = e.target.value;
    }

    data[e.target.id].inputValue = value;
    setData(data);
  }

  switch (step) {
    case ENTER_PAGE: return DisplayEnter(updateStep);
    case INPUT_PAGE: return DisplayFormInput(pageCounter, updatePage, updateStep, data, handleChange, errors, setErrors);
    case SUCCESS_PAGE: return DisplaySuccess();
    default: return DisplayEnter(updateStep);
  }

}

/*
* Form input pages 
* @param pageCounter - state for current page
* @param updatePage - state function to update pageCounter
* @param updateStep - state function to change step (enter, input, success)
*/
function DisplayFormInput(pageCounter, updatePage, updateStep, data, handleChange, errors, setErrors) {

  // Progress Bar
  let imgSource = "";
  if(pageCounter === 0) {
    imgSource = progress1;
  } else if (pageCounter === 1) {
    imgSource = progress2;
  } else if (pageCounter === 2) {
    imgSource = progress3;
  } else {
    imgSource = progress4;
  }

  // building question components
  const result = [];
  result.push(<h1 key='Header'>{Pages[pageCounter]}</h1>);
  for (let i = 0; i < data.length; i++) {
    if (data[i].page === Pages[pageCounter]) {
      result.push(<QuestionItem key={i} id={i} question={data[i]} handleChange={handleChange} />);
    }
  }

  // buttons depending on if first, middle, or last pages
  const buttons = [];
  if (pageCounter !== 0) buttons.push(<button key='PrevBtn' className='formButton' onClick={() => prevPage(pageCounter, updatePage, setErrors)} >Previous Page</button>);
  if (pageCounter === Pages.length - 1) buttons.push(<button key='SubmitBtn' className='formButton' onClick={() => submit(updateStep, data, result, setErrors) } >Submit</button>);
  else buttons.push(<button type='submit' key='NextBtn' className='formButton' onClick={() => nextPage(pageCounter, updatePage, data, result, setErrors)} >Next Page</button>);

  //page
  return (
    <>
      <div className="progress-bar">
        <img draggable="false" src={imgSource} id='progress-img'/>
      </div>
      <div className="form-box-container">
        <div className='formBox'>
          {result}
          <div className="buttons-and-errors">
            <div className="buttons">
              {buttons}
            </div>
            <div className="form-errors">
              <ul className="form-bullets">
                {errors.map(error => <li key={error}>{error}</li>)}
              </ul>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )


}

/*
* Beginning start page
* @param updateStep - state function to change step (enter, input, success)
*/
function DisplayEnter(updateStep) {
  return (
    <>
      <div className="formEnterPage">
        <div className='formEnter'>
          <div className="formEnterBody">
            <div className="formEnterText">
              <h1>Join the Revere Community</h1>
              <p>Placeholder text about the benefits of becoming a Revere rated fund manager. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <ul>
                <li>Benefit 1. Ut enim ad minim veniam.</li>
                <li>Benefit 2. Lorem ipsum dolor sit amet.</li>
              </ul>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div className="formEnterImg">
              <img draggable="false" src={splash} id='splash-img'/>
            </div>
          </div>
          <div className="formEnterButton">
            <button className='displayButton' onClick={() => updateStep(1)}>Begin Registration</button>
            <p><i class="fa-regular fa-clock"></i> Takes 3 minutes</p>
          </div>
        </div>
      </div>
      

    </>
  )
}

/*
* End success page
*/
function DisplaySuccess() {
  return (
    <>
      <div className="progress-bar">
        <img src={progress5} id='progress-img' />
      </div>
      <div className="formEnterPage">
        <div className='formEnter'>
          <div className="formEnterBody">
            <div className="formEnterText">
              <h1>Thank you for registering!</h1>
              <p>We will be in touch.</p>
              <p>In the meantime, please feel free to review our onboarding guide below.</p>
            </div>
            <div className="formEnterImg">
              <img src={splash} id='splash-img' />
            </div>
          </div>
          <div className="formEnterButton">
            <button className='displayButton'>Go to onboarding guide</button>
          </div>
        </div>
      </div>
    </>
  )
}

/*
* Button onClick action to go next page in form (update pageCounter state)
*/
function nextPage(pageCounter, updatePage, data, result, setErrors) {
  setErrors([]);
  if (pageCounter >= Pages.length - 1) return;
  let canProceed = validatePage(data, result, setErrors);

  if (canProceed === true) {
    updatePage(pageCounter + 1);
    setErrors([]);
  } else {
    setErrors(prevErrors => [...prevErrors, "Please fill in all required fields"]);
  }
}

/*
* Button onClick action to go previous page in form (update pageCounter state)
*/
function prevPage(pageCounter, updatePage, setErrors) {
  if (pageCounter <= 0) return;
  setErrors([])
  updatePage(pageCounter-1);
}

/*
* Button onClick action to submit form
* @param updateStep - state function to update step
* @param data - state for all questions
* @param result - array that holds the questions on the current page
* @param setErrors - state function to update errors to display
*/
function submit(updateStep, data, result, setErrors) {
  let canProceed = validatePage(data, result, setErrors);;

  if (canProceed === true) {
    setErrors(['Submitting...']);
    FirebaseConnect.initInstance();
    console.log(data);
    const postSubmit = httpsCallable(FirebaseConnect.functions, 'postRegistration'); // change to 'postRegistration' function to write to salesforce
    postSubmit(data)   
    .then(response => {
      setErrors([]);
      if (response.data.status !== 200) { // if we receive some sort of status back from firebase but bad request
        setErrors(["Failed to submit", response.data.message]); 
        console.log("Error:",response);
      }
      else {
        setErrors([]);
        console.log("Response:", response);
        updateStep(SUCCESS_PAGE);
      }

    })
    .catch( (error) => { // if post request fails (endpoint not reached/some other error)
      setErrors(["Failed to submit", "Please try again"]);
      console.log("Error:",error);
    });

  } else {
    setErrors(["Please fill in all required fields"]);
  }
  
}

/*
* Validates if the email is a string or a subset of ASCII characters that are separated into two parts by “@” symbol
* @param inputEmail - email inputted by the user 
*/
function validateEmail(inputEmail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(inputEmail)) {
    return true;
  } else {
    return false;
  }
}

/*
* Validates all required fields are filled out before moving on to next page
* @param data - state for all questions
* @param result - array that holds the questions on the current page
* @param setErrors - state function to update errors to display
*/
function validatePage(data, result, setErrors) {
  let canProceed = true;
  clearErrorStyling(result);

  // Loop through result starting at 1 to skip the Header
  for (let i = 1; i < result.length; i++) {
    let id = result[i].props.id;
    // Check if required inputs are emtpy
    if (data[id].required === true && (data[id].inputValue === "" || data[id].inputValue === [])) {
      canProceed = false;
      document.getElementById(id).classList.add("red-outline");
    }
    // Check if the email input is valid
    if (data[id].inputType === "email" && !validateEmail(data[id].inputValue)) {
      canProceed = false;
      document.getElementById(id).classList.add("red-outline");
      setErrors(prevErrors => [...prevErrors, "Invalid email"]);
    }
  }
  return canProceed;
}

/*
* Clears error styling when moving to next page
* @param result - array that holds the questions on the current page
*/
function clearErrorStyling(result) {
  for (let i = 1; i < result.length; i++) {
    let id = result[i].props.id;
    document.getElementById(id).classList.remove("red-outline");
  }
}
// moved to submit function
/*
function postSubmitRequest(dictionary) {
  FirebaseConnect.initInstance();
  console.log(dictionary);
  const postSubmit = httpsCallable(FirebaseConnect.functions, 'sampleSubmitRequest');
  postSubmit(dictionary)   
  .then(response => console.log(response))
  .catch(error => console.log("Error code:", error));

}*/


export default MainPage;

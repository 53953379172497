import QuestionItem from "../QuestionItem/QuestionItem";
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from "react"
import FirebaseConnect from "../../FirebaseConnect";
import './Benchmarking.css'
import progress1 from '../../assets/progress1.png';
import progress2 from '../../assets/progress2.png';
import progress3 from '../../assets/progress3.png';
import progress4 from '../../assets/progress4.png';
import progress5 from '../../assets/progress5.png';
import splash from '../../assets/splash.png';
// import { initializeAnalytics, logEvent } from "firebase/analytics";


const ENTER_PAGE = 0;
const INPUT_PAGE = 1;
const SUCCESS_PAGE = 2;

// I had to disable GA because idk why it wouldn't work ... 
FirebaseConnect.initInstance();
// logEvent(FirebaseConnect.analytics, 'Benchmarking_Survey_Viewed')

const Pages = ['Firm Info', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function MainPage() {
  const defaultDictionary = [
    {
      question: "First Name",
      page: "Firm Info",
      inputType: "text",
      inputOptions: "",
      exampleText: "John",
      salesforceField: "FirstName",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    }, 
    {
      question: "Last Name",
      page: "Firm Info",
      inputType: "text",
      inputOptions: "",
      exampleText: "Smith",
      salesforceField: "LastName",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    }, 
    {
      question: "Email Address",
      page: "Firm Info",
      inputType: "email",
      inputOptions: "",
      exampleText: "john.smith@reverevc.com",
      salesforceField: "Email",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    },
    {
      question: "",
      page: "",
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "RecordTypeId",
      salesforceObject: "Contact",
      required: false,
      inputValue: "0125e000000ibQzAAI"
    },
    {
      question: "",
      page: "",
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Benchmarking_Survey_Bucket__c",
      salesforceObject: "Contact",
      required: false,
      inputValue: "New Contact"
    },
    {
      question: "",
      page: "",
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Benchmarking_Survey_Complete__c",
      salesforceObject: "Contact",
      required: false,
      inputValue: "No",
    },
    {
      question: "Firm Name",
      page: "Firm Info",
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Firm__c",
      required: true,
      inputValue: ""
    },
    {
      question: "What is your firm's website?",
      page: "Firm Info",
      inputType: "text",
      inputOptions: "",
      exampleText: "www.reverevc.com",
      salesforceField: "Website__c",
      salesforceObject: "Firm__c",
      required: false,
      inputValue: ""
    },
    {
      question: "Fund Name",
      page: 1,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Vintage Year",
      page: 1,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Vintage__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Number",
      page: 1,
      inputType: "number",
      inputOptions: "",
      exampleText: "ie '1' for a Fund I",
      salesforceField: "Fund_Number__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Most Recent Unaudited Financial Statement PDF Upload',
      page: 1,
      inputType: "Benchmarking Google Drive Link",
      inputOptions: "",
      exampleText: '',
      salesforceField: "Financial_Statement_Link__c",
      salesforceObject: "Fund_Performance__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Sector Focus',
      page: 1,
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Stage Focus',
      page: 1,
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "How many Partners managed the fund?",
      page: 1,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Number_of_Partners__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 1,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 1,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Woman_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) managed funds at prior firms?',
      page: 1,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "GPs_managed_funds_at_prior_firms__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) had successful exits as startup founders?',
      page: 1,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Successful_Exits_as_Startup_Founder__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 1,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Agreed_to_Benchmarking_Survey_TOU__c",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Name",
      page: 2,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Vintage",
      page: 2,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Vintage__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Number",
      page: 2,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Fund_Number__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Most Recent Unaudited Financial Statement PDF Upload',
      page: 2,
      inputType: "Benchmarking Google Drive Link",
      inputOptions: "",
      exampleText: '',
      salesforceField: "Financial_Statement_Link__c",
      salesforceObject: "Fund_Performance__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Sector Focus',
      page: 2,
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Stage Focus',
      page: 2,
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "How many Partners managed the fund?",
      page: 2,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Number_of_Partners__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 2,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 2,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Woman_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) managed funds at prior firms?',
      page: 2,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "GPs_managed_funds_at_prior_firms__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) had successful exits as startup founders?',
      page: 2,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Successful_Exits_as_Startup_Founder__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 2,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Agreed_to_Benchmarking_Survey_TOU__c",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Name",
      page: 3,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Vintage",
      page: 3,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Vintage__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Number",
      page: 3,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Fund_Number__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Most Recent Unaudited Financial Statement PDF Upload',
      page: 3,
      inputType: "Benchmarking Google Drive Link",
      inputOptions: "",
      exampleText: '',
      salesforceField: "Financial_Statement_Link__c",
      salesforceObject: "Fund_Performance__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Sector Focus',
      page: 3,
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Stage Focus',
      page: 3,
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "How many Partners managed the fund?",
      page: 3,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Number_of_Partners__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 3,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 3,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Woman_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) managed funds at prior firms?',
      page: 3,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "GPs_managed_funds_at_prior_firms__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) had successful exits as startup founders?',
      page: 3,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Successful_Exits_as_Startup_Founder__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 3,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Agreed_to_Benchmarking_Survey_TOU__c",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Name",
      page: 4,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Vintage",
      page: 4,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Vintage__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Number",
      page: 4,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Fund_Number__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Most Recent Unaudited Financial Statement PDF Upload',
      page: 4,
      inputType: "Benchmarking Google Drive Link",
      inputOptions: "",
      exampleText: '',
      salesforceField: "Financial_Statement_Link__c",
      salesforceObject: "Fund_Performance__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Sector Focus',
      page: 4,
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Stage Focus',
      page: 4,
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "How many Partners managed the fund?",
      page: 4,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Number_of_Partners__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 4,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 4,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Woman_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) managed funds at prior firms?',
      page: 4,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "GPs_managed_funds_at_prior_firms__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) had successful exits as startup founders?',
      page: 4,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Successful_Exits_as_Startup_Founder__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 4,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Agreed_to_Benchmarking_Survey_TOU__c",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Name",
      page: 5,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Vintage",
      page: 5,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Vintage__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Number",
      page: 5,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Fund_Number__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Most Recent Unaudited Financial Statement PDF Upload',
      page: 5,
      inputType: "Benchmarking Google Drive Link",
      inputOptions: "",
      exampleText: '',
      salesforceField: "Financial_Statement_Link__c",
      salesforceObject: "Fund_Performance__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Sector Focus',
      page: 5,
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Stage Focus',
      page: 5,
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "How many Partners managed the fund?",
      page: 5,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Number_of_Partners__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 5,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 5,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Woman_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) managed funds at prior firms?',
      page: 5,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "GPs_managed_funds_at_prior_firms__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) had successful exits as startup founders?',
      page: 5,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Successful_Exits_as_Startup_Founder__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 5,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Agreed_to_Benchmarking_Survey_TOU__c",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Name",
      page: 6,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Vintage",
      page: 6,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Vintage__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Number",
      page: 6,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Fund_Number__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Most Recent Unaudited Financial Statement PDF Upload',
      page: 6,
      inputType: "Benchmarking Google Drive Link",
      inputOptions: "",
      exampleText: '',
      salesforceField: "Financial_Statement_Link__c",
      salesforceObject: "Fund_Performance__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Sector Focus',
      page: 6,
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Stage Focus',
      page: 6,
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "How many Partners managed the fund?",
      page: 6,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Number_of_Partners__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 6,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 6,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Woman_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) managed funds at prior firms?',
      page: 6,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "GPs_managed_funds_at_prior_firms__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) had successful exits as startup founders?',
      page: 6,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Successful_Exits_as_Startup_Founder__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 6,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Agreed_to_Benchmarking_Survey_TOU__c",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Name",
      page: 7,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Vintage",
      page: 7,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Vintage__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Number",
      page: 7,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Fund_Number__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Most Recent Unaudited Financial Statement PDF Upload',
      page: 7,
      inputType: "Benchmarking Google Drive Link",
      inputOptions: "",
      exampleText: '',
      salesforceField: "Financial_Statement_Link__c",
      salesforceObject: "Fund_Performance__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Sector Focus',
      page: 7,
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Stage Focus',
      page: 7,
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "How many Partners managed the fund?",
      page: 7,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Number_of_Partners__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 7,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 7,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Woman_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) managed funds at prior firms?',
      page: 7,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "GPs_managed_funds_at_prior_firms__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) had successful exits as startup founders?',
      page: 7,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Successful_Exits_as_Startup_Founder__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 7,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Agreed_to_Benchmarking_Survey_TOU__c",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Name",
      page: 8,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Vintage",
      page: 8,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Vintage__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Number",
      page: 8,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Fund_Number__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Most Recent Unaudited Financial Statement PDF Upload',
      page: 8,
      inputType: "Benchmarking Google Drive Link",
      inputOptions: "",
      exampleText: '',
      salesforceField: "Financial_Statement_Link__c",
      salesforceObject: "Fund_Performance__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Sector Focus',
      page: 8,
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Stage Focus',
      page: 8,
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "How many Partners managed the fund?",
      page: 8,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Number_of_Partners__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 8,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 8,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Woman_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) managed funds at prior firms?',
      page: 8,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "GPs_managed_funds_at_prior_firms__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) had successful exits as startup founders?',
      page: 8,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Successful_Exits_as_Startup_Founder__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 8,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Agreed_to_Benchmarking_Survey_TOU__c",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Name",
      page: 9,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Vintage",
      page: 9,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Vintage__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Number",
      page: 9,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Fund_Number__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Most Recent Financial Statement PDF Upload',
      page: 9,
      inputType: "Benchmarking Google Drive Link",
      inputOptions: "",
      exampleText: '',
      salesforceField: "Financial_Statement_Link__c",
      salesforceObject: "Fund_Performance__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Sector Focus',
      page: 9,
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Stage Focus',
      page: 9,
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "How many Partners managed the fund?",
      page: 9,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Number_of_Partners__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 9,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 9,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Woman_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) managed funds at prior firms?',
      page: 9,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "GPs_managed_funds_at_prior_firms__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) had successful exits as startup founders?',
      page: 9,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Successful_Exits_as_Startup_Founder__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 9,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Agreed_to_Benchmarking_Survey_TOU__c",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Name",
      page: 10,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Name",
      salesforceObject: "Fund__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Vintage",
      page: 10,
      inputType: "text",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Vintage__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "Fund Number",
      page: 10,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Fund_Number__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Most Recent Unaudited Financial Statement PDF Upload',
      page: 10,
      inputType: "Benchmarking Google Drive Link",
      inputOptions: "",
      exampleText: '',
      salesforceField: "Financial_Statement_Link__c",
      salesforceObject: "Fund_Performance__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Sector Focus',
      page: 10,
      inputType: 'Single Select',
      inputOptions: ['Consumer', 'Enterprise', 'Deep Tech', 'FinTech', 'Impact', 'Life Sciences', 'Web3', 'Agnostic'],
      exampleText: '',
      salesforceField: "Primary_Industry__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Primary Stage Focus',
      page: 10,
      inputType: 'Multi Select',
      inputOptions: ['Pre-Seed', 'Seed', 'Series A', 'Series B', 'Growth'],
      exampleText: '',
      salesforceField: "Investment_Stage_Focus__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: "How many Partners managed the fund?",
      page: 10,
      inputType: "number",
      inputOptions: "",
      exampleText: "",
      salesforceField: "Number_of_Partners__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: true,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as Diverse?',
      page: 10,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Diverse_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Does one or more Partners of the fund identify as female?',
      page: 10,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Woman_GP__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) managed funds at prior firms?',
      page: 10,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "GPs_managed_funds_at_prior_firms__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Have the GP(s) had successful exits as startup founders?',
      page: 10,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Successful_Exits_as_Startup_Founder__c",
      salesforceObject: "Revere_Rating_Diligence__c",
      required: false,
      inputValue: ""
    },
    {
      question: 'Do you agree to our terms of use?',
      page: 10,
      inputType: "Single Select",
      inputOptions: ['Yes', 'No'],
      exampleText: '',
      salesforceField: "Agreed_to_Benchmarking_Survey_TOU__c",
      salesforceObject: "Contact",
      required: true,
      inputValue: ""
    }
  ];

  const [step, updateStep] = useState(ENTER_PAGE);

  const [pageCounter, updatePage] = useState(0); // page counter initiated to 0
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState(defaultDictionary);
  const [sfIds, setSfIds] = useState({
    "contactId": "",
    "firmId": "",
    "fundId": "",
    "diligenceId": "",
    "fundPerformanceId": ""
  });
  const [submitFirstFund, setSubmitFirstFund] = useState(false);
  const [parentFolder, setParentFolder] = useState("");
  const [statusIcon, setStatusIcon] = useState("");
  const [addFundMessage, setAddFundMessage] = useState("");
  const [loadingIcon, setLoadingIcon] = useState("");

  const handleChange = (e, fileStatus) => {
    const type = e.target.type;

    let id;
    if (type === 'file') {
      // The form ID's look like 'form-11' for example
      // Need to remove the 'form-' so we can index into the number
      const formId = e.target.form.id;
      id = Number(formId.substring(5));
    } else {
      id = e.target.id
    }

    // Get the proper inputValue based on type of question (select-multiple, file, text, etc)
    let value;
    if (type === "select-multiple") {
      value = Array.from(e.target.selectedOptions, option => option.value)
    } else if (type === "file") {
      value = fileStatus;
    } else if (e.target.name === "Vintage__c") {
      value = e.target.value + "-01-01T00:00:00Z";
    } else {
      value = e.target.value;
    }

    data[id].inputValue = value;
    setData(data);
    console.log("data", data)
    //console.log(e.target.name);
  }

  switch (step) {
    case ENTER_PAGE: return DisplayEnter(updateStep);
    case INPUT_PAGE: return DisplayFormInput(pageCounter, updatePage, updateStep, data, setData, handleChange, errors, setErrors, setSfIds, sfIds, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, addFundMessage, setAddFundMessage, statusIcon, setStatusIcon, loadingIcon, setLoadingIcon);
    case SUCCESS_PAGE: return DisplaySuccess();
    default: return DisplayEnter(updateStep);
  }

}

/*
* Form input pages 
* @param pageCounter - state for current page
* @param updatePage - state function to update pageCounter
* @param updateStep - state function to change step (enter, input, success)
*/
function DisplayFormInput(pageCounter, updatePage, updateStep, data, setData, handleChange, errors, setErrors, setSfIds, sfIds, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, addFundMessage, setAddFundMessage, statusIcon, setStatusIcon, loadingIcon, setLoadingIcon) {

  // building question components
  const result = [];
  if (Pages[pageCounter] === 'Firm Info') {
    result.push(<h1 key='Header'>Firm Info</h1>);
    result.push(<p key='SubHeader'>Providing your contact info will allow us to send the survey results to you.</p>);
  } else {
    result.push(<h1 key='Header'>Fund Info</h1>);
    result.push(<p key='SubHeader'>Please provde information pertaining to an individual fund.</p>);
  }
  for (let i = 0; i < data.length; i++) {
    if (data[i].page === Pages[pageCounter]) {
      result.push(<QuestionItem key={i} id={i} question={data[i]} handleChange={handleChange} />);
    }
  }

  // buttons depending on if first, middle, or last pages
  const buttons = [];
  if (pageCounter === 0) {
    // Firm info page. Just have 'Next' button available 
    buttons.push(<button type='submit' key='NextBtn' className='formButton' onClick={() => {nextPage(pageCounter, updatePage, data, result, setErrors); }} >Next Page</button>);
  } else if (pageCounter === Pages.length - 1) {
    // last page. Just have 'complete and submit' button available
    buttons.push(<button key='SubmitBtn' className='formButton' onClick={() => {submit(updateStep, data, setData, result, setErrors, setSfIds, sfIds, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, pageCounter, loadingIcon, setLoadingIcon);}} >Complete and Submit</button>);
  } else {
    // Middle pages. Have 'complete and submit' and 'submit and add' buttons available 
    buttons.push(<button key='SubmitAddBtn' className='formButton' onClick={() => submitAndAdd(updateStep, data, setData, result, setErrors, setSfIds, sfIds, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, addFundMessage, setAddFundMessage, statusIcon, setStatusIcon, pageCounter, updatePage, loadingIcon, setLoadingIcon)} >Submit Fund and Add Another Fund</button>);

    buttons.push(<button key='SubmitBtn' className='formButton' onClick={() => {submit(updateStep, data, setData, result, setErrors, setSfIds, sfIds, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, pageCounter, loadingIcon, setLoadingIcon); }} >Complete and Submit</button>);
  }

  //page
  return (
    <>
      <div className="progress-bar">
        {/* <img draggable="false" src={imgSource} id='progress-img'/> */}
      </div>
      <div className="form-box-container">
        <div className='formBox'>
          <div className="success-upload">
            {statusIcon}
            {addFundMessage}
          </div>
          {result}
          <div className="buttons-and-errors">
            <div className="buttons">
              {buttons}
            </div>
            <div className="form-errors">
              <ul className="form-bullets">
                {errors.map(error => <li key={error}>{error}</li>)}
              </ul>
            </div>
            <div className="loading-icon">
              {loadingIcon}
            </div>
          </div>
        </div>
      </div>
      
    </>
  )


}

/*
* Beginning start page
* @param updateStep - state function to change step (enter, input, success)
*/
function DisplayEnter(updateStep) {

  return (
    <>
      <div className="formEnterPage">
        <div className='formEnter'>
          <div className="formEnterBody">
            <div className="formEnterText">
              <h1>2023 Emerging Manager Benchmarking Report</h1>
              <p>So far, we have data on 200+ funds across 15 years worth of vintages, and we want YOU to participate!</p>
              <p><b>What's the process?</b></p>
              <ul>
                <li>Simply answer a few survey questions about your firm, and then upload your <span style={{ color: '#FF755B' }}><b>Unaudited Q4 2022 Financial Statement PDF. Please note, these pdfs should be directly from your fund admin.</b></span></li>
                <li>If you have more than one fund for your firm, you will be able to upload statements for each.</li>
                <li>Please note, all data will be kept private. Only aggregated, anonymous fund level returns will be shared in our report.</li>
              </ul>
              <p><b>What do you get out of it?</b></p>
              <ul>
                <li>Benchmark your fund against other emerging managers - instead of large funds with survivorship biases.</li>
                <li>Top performers in their respective categories will earn Revere Badges to showcase to LPs.</li>
              </ul>
              <p>Once we have collected data from all participating funds, we will send you a copy of the report, as well as your individual ranking for each of your funds.</p>
            </div>
            <div className="formEnterImg">
              <img src={splash} id='splash-img'/>
            </div>
          </div>
          <div className="formEnterButton">
            <button className='displayButton' onClick={() => {updateStep(1); }}>Begin Survey</button>
            <p><i class="fa-regular fa-clock"></i> Takes 3 minutes</p>
          </div>
        </div>
      </div>
      

    </>
  )
}

/*
* End success page
*/
function DisplaySuccess() {
  return (
    <>
      <div className="progress-bar">
        {/* <img src={progress5} id='progress-img' /> */}
      </div>
      <div className="formEnterPage">
        <div className='formEnter'>
          <div className="formEnterBody">
            <div className="formEnterText">
              <h1>Thank you for your participation in our survey!</h1>
              <p>We will be in touch with the results within the next few weeks.</p>
              <p>In the meantime please feel free to connect with us on social media.</p>
            </div>
            <div className="formEnterImg">
              <img src={splash} id='splash-img' />
            </div>
            <div>
              <a href="https://www.linkedin.com/company/reverevc" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
              <i class="fa-brands fa-linkedin-in fa-xl"></i>
              </a>
              {'  \u00A0\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0\u00A0  '}
              <a href="https://twitter.com/revere_vc" target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
              <i class="fa-brands fa-twitter fa-xl"></i>
              </a>
              {'  \u00A0\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0\u00A0  '}
              <a href="mailto:ratings@reverevc.com" style={{ color: 'white' }}>
                ratings@reverevc.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

/*
* Button onClick action to go next page in form (update pageCounter state)
*/
function nextPage(pageCounter, updatePage, data, result, setErrors) {
  setErrors([]);
  if (pageCounter >= Pages.length - 1) return;
  let canProceed = validatePage(data, result, setErrors, pageCounter);

  if (canProceed === true) {
    updatePage(pageCounter + 1);
    setErrors([]);
  } else {
    setErrors(prevErrors => [...prevErrors, "Please fill in all required fields"]);
  }
}

/*
* Button onClick action to go previous page in form (update pageCounter state)
*/
function prevPage(pageCounter, updatePage, setErrors) {
  if (pageCounter <= 0) return;
  setErrors([])
  updatePage(pageCounter-1);
}

/*
* Button onClick action to submit form
* @param updateStep - state function to update step
* @param data - state for all questions
* @param result - array that holds the questions on the current page
* @param setErrors - state function to update errors to display
*/
async function submit(updateStep, data, setData, result, setErrors, setSfIds, sfIds, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, pageCounter, loadingIcon, setLoadingIcon) {
  let canProceed = validatePage(data, result, setErrors, pageCounter);
  if(canProceed === true) {
    // console.log("can proceed");
    setErrors(['Submitting... This may take a moment'])
    setLoadingIcon(<i class="fas fa-circle-notch fa-spin"></i>);
    if(submitFirstFund === false) {
      // This is the first fund they are submitting. Need to create all objects and google drive folder ID
      // Toggle "Benchmarking_Survey_Complete__c" to "Yes"
      data[5].inputValue = "Yes";
      setData(data);

      uploadFile(data, setData, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, pageCounter)
        .then((result) => {
          const dataToSend = data.slice(0, 20);
          // console.log("dataToSend", dataToSend);
          const postBenchmarking = httpsCallable(FirebaseConnect.functions, 'postBenchmarking');
          postBenchmarking(dataToSend)
            .then((response) => {
              setErrors([]);
              // console.log("response", response);
              if(response.data.status !== 200) {
                // setErrors(["Failed to submit, please double check fields. Or try again later", response.data.message]); 
                // setErrors(["Failed to submit, please double check fields. Or try again later"]);
                // we are trusting that we can figure out the errors from the backend so we'll tell the user everything is fine
                updateStep(SUCCESS_PAGE);
                // logEvent(initializeAnalytics.analytics, "Benchmarking_Survey_Submit_Error"); 
                setLoadingIcon('');
                // console.log("Error:",response.message);
              } else {
                setSfIds({
                  "contactId": response.data.sfIDs.Contact,
                  "firmId": response.data.sfIDs.Firm__c,
                  "fundId": response.data.sfIDs.Fund__c,
                  "diligenceId": response.data.sfIDs.Revere_Rating_Diligence__c,
                  "fundPerformanceId": response.data.sfIDs.Fund_Performance__c
                })
                // console.log("sfIds", sfIds);
                // console.log("Response:", response);
                setErrors([]);
                setLoadingIcon('');
                setSubmitFirstFund(true);
                updateStep(SUCCESS_PAGE);
              }
            })
        })
    } else {
      // They have already submitted a fund. Need to just create fund, diligence, and fund performance objects
      uploadFile(data, setData, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, pageCounter)
        .then((result) => {
          const startIdx = (pageCounter - 1) * 12 + 8;
          const endIdx = startIdx + 12;
          const dataToSend = data.slice(startIdx, endIdx);
          // console.log("dataToSend", dataToSend);
          const postAdditionalBenchmarking = httpsCallable(FirebaseConnect.functions, "postAdditionalBenchmarking");
          postAdditionalBenchmarking({ 'dictionary': dataToSend, 'firmId': sfIds['firmId'] })
            .then((response) => {
              setErrors([]);
              setLoadingIcon('');
              if (response.data.status !== 200) {
                // setErrors(["Failed to submit", response.data.message]);
                // console.log("Error:", response.message);
                // we are trusting that we can figure out the errors from the backend so we'll tell the user everything is fine
                updateStep(SUCCESS_PAGE);
              } else {
                // console.log("response", response);
                updateStep(SUCCESS_PAGE);
              }
            })
        })
    }
  } else {
    setErrors(["Please fill in all required fields"]);
  }
}

async function submitAndAdd(updateStep, data, setData, result, setErrors, setSfIds, sfIds, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, addFundMessage, setAddFundMessage, statusIcon, setStatusIcon, pageCounter, updatePage, loadingIcon, setLoadingIcon) {
  let canProceed = validatePage(data, result, setErrors, pageCounter);
  if (canProceed === true) {
    // console.log("can proceed");
    setErrors(['Submitting... This may take a moment']);
    setLoadingIcon(<i class="fas fa-circle-notch fa-spin"></i>);
    if (submitFirstFund === false) {
      // Toggle "Benchmarking_Survey_Complete__c" to "Yes"
      data[5].inputValue = "Yes";
      setData(data);

      // This is the first fund they are submitting. Need to create all objects and google drive folder ID
      uploadFile(data, setData, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, pageCounter)
        .then((result) => {
          const dataToSend = data.slice(0, 20);
          // console.log("dataToSend", dataToSend);
          // console.log("data", data);

          const postBenchmarking = httpsCallable(FirebaseConnect.functions, 'postBenchmarking');
          postBenchmarking(dataToSend)
            .then((response) => {
              setErrors([]);
              // console.log("response", response);
              if (response.data.status !== 200) {
                // setErrors(["Failed to submit", response.data.message]);
                // we are trusting that we can figure out the errors from the backend so we'll tell the user everything is fine
                updateStep(SUCCESS_PAGE);
                setStatusIcon('');
                setAddFundMessage('');
                setLoadingIcon('');
                // console.log("Error:", response.message);
              } else {
                setSfIds({
                  "contactId": response.data.sfIDs.Contact,
                  "firmId": response.data.sfIDs.Firm__c,
                  "fundId": response.data.sfIDs.Fund__c,
                  "diligenceId": response.data.sfIDs.Revere_Rating_Diligence__c,
                  "fundPerformanceId": response.data.sfIDs.Fund_Performance__c
                })
                const fundName = dataToSend[8].inputValue;
                // console.log("sfIds", sfIds);
                // console.log("Response:", response);
                setErrors([]);
                setLoadingIcon('');
                setSubmitFirstFund(true);
                setStatusIcon(<i class="fa-solid fa-check success-upload"></i>);
                setAddFundMessage(`Successfully submitted fund: ${fundName}`);
                updatePage(pageCounter + 1);
                setErrors([]);
                window.scrollTo(0, 0);
              }
            })
        })
    } else {
      // They have already submitted a fund. Need to just create fund, diligence, and fund performance objects
      uploadFile(data, setData, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, pageCounter)
        .then((result) => {
          const startIdx = (pageCounter - 1) * 12 + 8;
          const endIdx = startIdx + 12;
          const dataToSend = data.slice(startIdx, endIdx);
          // console.log("dataToSend", dataToSend);
          // console.log("data", data);

          const postAdditionalBenchmarking = httpsCallable(FirebaseConnect.functions, "postAdditionalBenchmarking");
          postAdditionalBenchmarking({ 'dictionary': dataToSend, 'firmId': sfIds['firmId'] })
            .then((response) => {
              setErrors([]);
              if (response.data.status !== 200) {
                // setErrors(["Failed to submit", response.data.message]);
                // setErrors(["Failed to submit, please double check fields. Or try again later"]);
                // we are trusting that we can figure out the errors from the backend so we'll tell the user everything is fine
                updateStep(SUCCESS_PAGE);
                // logEvent(initializeAnalytics.analytics, "Benchmarking_Survey_Submit_Error"); 
                setStatusIcon('');
                setLoadingIcon('');
                setAddFundMessage('');
                // console.log("Error:", response.message);
              } else {
                const fundName = dataToSend[0].inputValue;
                // console.log("response", response);
                setErrors([]);
                setLoadingIcon('');
                setStatusIcon(<i class="fa-solid fa-check success-upload"></i>);
                setAddFundMessage(`Successfully submitted fund: ${fundName}`);
                updatePage(pageCounter + 1);
                window.scrollTo(0, 0);
              }
            })
        })
    }
  } else {
    setErrors(["Please fill in all required fields"]);
  }
}

/*
* Validates if the email is a string or a subset of ASCII characters that are separated into two parts by “@” symbol
* @param inputEmail - email inputted by the user 
*/
function validateEmail(inputEmail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(inputEmail)) {
    return true;
  } else {
    return false;
  }
}

/*
* Validates all required fields are filled out before moving on to next page
* @param data - state for all questions
* @param result - array that holds the questions on the current page
* @param setErrors - state function to update errors to display
*/
function validatePage(data, result, setErrors, pageCounter) {
  let canProceed = true;
  clearErrorStyling(result);

  // Loop through result starting at 2 to skip the Header and subheader
  for (let i = 2; i < result.length; i++) {
    let id = result[i].props.id;
    // Check if required inputs are emtpy
    if (data[id].required === true && (data[id].inputValue === "" || data[id].inputValue === [] || data[id].inputValue === null)) {
      canProceed = false;
      // document.getElementById(id).classList.add("red-outline");
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.add("red-outline");
      } else {
        id = `form-${result[i].props.id}`;
        document.getElementById(id).classList.add("red-outline");
      }
    }
    // Check if the email input is valid
    if (pageCounter === 0 && data[id].inputType === "email" && !validateEmail(data[id].inputValue)) {
      canProceed = false;
      document.getElementById(id).classList.add("red-outline");
      setErrors(prevErrors => [...prevErrors, "Invalid email"]);
    }
  }
  return canProceed;
}

/*
* Clears error styling when moving to next page
* @param result - array that holds the questions on the current page
*/
function clearErrorStyling(result) {
  for (let i = 2; i < result.length; i++) {
    let id = result[i].props.id
    if (document.getElementById(id) !== null) {
      document.getElementById(id).classList.remove("red-outline");
    } else {
      id = `form-${result[i].props.id}`;
      document.getElementById(id).classList.remove("red-outline");
    }
  }
}

async function uploadFile(data, setData, submitFirstFund, setSubmitFirstFund, parentFolder, setParentFolder, pageCounter) {
  return new Promise(async(resolve, reject) => {
    // If first fund, create folder and save folder ID to state
    if (submitFirstFund === false) {
      try {
        const benchmarkingCreateFolder = httpsCallable(FirebaseConnect.functions, 'benchmarkingCreateFolder');
        benchmarkingCreateFolder({ 'folderName': data[6].inputValue })
          .then(async (res) => {
            // console.log("successfully created folder", res);
            // console.log("res.data.folderId", res.data.folderId);
            const folderId = res.data.folderId;
            setParentFolder(folderId);
  
            const formElem = document.querySelector('form');
            const formData = new FormData(formElem);
  
            const fundNameIdx = (pageCounter - 1) * 12 + 8;
            const fundName = data[fundNameIdx].inputValue;
            const fileLinkIdx = (pageCounter - 1) * 12 + 11;
  
            const url = `https://us-central1-pipeline-tool-playground.cloudfunctions.net/benchmarkingUploadToDrive?fundName=${encodeURIComponent(fundName)}&parentFolder=${encodeURIComponent(folderId)}`;
  
            const requestOptions = {
              method: 'POST',
              body: formData
            };
            const response = await fetch(url, requestOptions);
            const driveData = await response.json();
            if (driveData.data.status !== 200) {
              // console.log("error uploading file");
            } else {
              data[fileLinkIdx].inputValue = driveData.data.fileLink;
              setData(data);
              resolve(data);
            }
          })
      } catch (error) {
        reject(error);
      }
    } else {
      try {
        const formElem = document.querySelector('form');
        const formData = new FormData(formElem);

        const fundNameIdx = (pageCounter - 1) * 12 + 8;
        const fundName = data[fundNameIdx].inputValue;
        const fileLinkIdx = (pageCounter - 1) * 12 + 11;

        const url = `https://us-central1-pipeline-tool-playground.cloudfunctions.net/benchmarkingUploadToDrive?fundName=${encodeURIComponent(fundName)}&parentFolder=${encodeURIComponent(parentFolder)}`;

        const requestOptions = {
          method: 'POST',
          body: formData
        };
        const response = await fetch(url, requestOptions);
        const driveData = await response.json();
        if (driveData.data.status !== 200) {
          // console.log("error uploading file");
        } else {
          data[fileLinkIdx].inputValue = driveData.data.fileLink;
          setData(data);
          resolve(data);
        }
      } catch (error) {
        reject(error);
      }
    }
  })
}

export default MainPage;

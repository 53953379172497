import Registration from "./components/Registration/Registration";
import DocGather from "./components/DocGather/DocGather";
import NavBar from "./components/NavBar/NavBar";
import HomePage from "./components/HomePage/HomePage";
import MainPage from "./components/Benchmarking/Benchmarking";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {

  document.title="Revere Registration Page"
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="docgather" element={<DocGather />} />
        {/* <Route path="register" element={<Registration />} /> */}
        <Route path="benchmarking" element={<MainPage />} />
      </Routes>
    </Router>
  );
}

export default App;

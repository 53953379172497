import React, { useState } from 'react';
import './ToolTip.css'; 

function Tooltip({ children, content }) {
  const [showTooltip, setTooltip] = useState(false);

  return (
    <div 
      className="tooltip-container" 
      onMouseEnter={() => setTooltip(true)} 
      onMouseLeave={() => setTooltip(false)}
    >
      {children}
      {showTooltip && <div className="tooltip-box">{content}</div>}
    </div>
  );
}

export default Tooltip;

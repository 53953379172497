import { useEffect, useState } from 'react';
import './QuestionItem.css'
import FirebaseConnect from '../../FirebaseConnect';
import { httpsCallable } from 'firebase/functions';
import Tooltip from './ToolTip/ToolTip';

function QuestionItem({ id, question, handleChange, fileMetaData }) {
  const [uploadStatus, setUploadStatus] = useState(''); // Keeps track of text status
  const [statusIcon, setStatusIcon] = useState(''); // Keeps track of the icon (check or X) depending on status
  const [alreadyUploaded, setAlreadyUploaded] = useState(false); // Keeps track of if the user has already uploaded this file or not

  useEffect(() => {
    // If there is a google drive link, set the icon to a successful check mark, and text to let the know the user has already uploaded this type of file
    if (question.inputType === "Google Drive Link" && question.inputValue !== null) {
      setStatusIcon(<i class="fa-solid fa-check success-upload"></i>);
      setUploadStatus('Previously uploaded');
      setAlreadyUploaded(true);
    }
  }, []);

  const getNextVersion = async () => {
    // If it's their first time uploading this file, version is 01
    // Else, get the file name using the file link and increment the version
    return new Promise((resolve, reject) => {
      if(alreadyUploaded === false) {
        resolve('01');
      } else {
        let nextVersion;

        // Need to extract fileId from the current link stored in the dictionary
        // const fileLink = 'https://drive.google.com/file/d/FILE_ID/view';
        const fileLink = question.inputValue;
        let startIdx = fileLink.indexOf('/d/');
        startIdx += 3;
        const endIdx = fileLink.indexOf('/view');
        const fileId = fileLink.substring(startIdx, endIdx);
        console.log("fileId", fileId);
    
        FirebaseConnect.initInstance();
        const getFileName = httpsCallable(FirebaseConnect.functions, 'getFileName');
        getFileName({ 'fileId': fileId })
          .then((response) => {
            if (response.data.status === 200) {
              console.log("success response", response);
              // Need to manipulate fileVersion
              // Ex: "Fund Name (Test A)_Data Room - Track Record Link__v01"
              const fileName = response.data.fileName;
              const versionString = fileName.match(/__v\d+$/)[0].substring(3); // use regex to match what is after "__v"
              console.log("version", versionString)
              let versionNumber = Number(versionString);
              versionNumber += 1;
              if (versionNumber <= 9) {
                nextVersion = "0" + String(versionNumber);
                resolve(nextVersion);
              } else {
                nextVersion = String(versionNumber);
                resolve(nextVersion);
              }
              return nextVersion;
            } else {
              console.log("error response", response);
              reject(new Error("Failed to get file name"));
            }
          })
          .catch((error) => {
            console.error("Error while getting file name:", error);
            reject(error);
          })
      }
    })
  }

  // Upload the file to Google Drive and get the link to save into the dictionary
  const handleFileUpload = async (e) => {
    setUploadStatus('Uploading file...');
    const fileVersion = await getNextVersion();

    let fileName = question.question + '__v' + fileVersion;
    console.log("fileName", fileName);

    // The form ID's look like 'form-11' for example
    // Need to remove the 'form-' so we can index into the number
    const formId = e.target.form.id;
    const id = Number(formId.substring(5));

    const formElem = document.querySelector(`#${e.target.form.id}`);
    const formData = new FormData(formElem);

    // formData.append('fundName', fundName);
    // formData.append('fileName', fileName);

    const url = `https://us-central1-pipeline-tool-playground.cloudfunctions.net/uploadToDrive?fundName=${encodeURIComponent(fileMetaData.fundName)}&fileName=${encodeURIComponent(fileName)}&parentFolder=${encodeURIComponent(fileMetaData.parentFolder)}`;

    const requestOptions = {
      method: 'POST',
      body: formData
    };

    // const response = await fetch('https://us-central1-pipeline-tool-playground.cloudfunctions.net/uploadToDrive', requestOptions);

    const response = await fetch(url, requestOptions);
    const driveData = await response.json();
    if(driveData.data.status !== 200) {
      setStatusIcon(<i class="fa-solid fa-x unsuccess-upload"></i>);
      setUploadStatus('Error uploading file. Please try again later.');
    } else {
      setStatusIcon(<i class="fa-solid fa-check success-upload"></i>);
      setUploadStatus('Uploaded successfully');
      setAlreadyUploaded(true);
    }
    const driveLink = driveData.data.fileLink;
    handleChange(e, driveLink); // Save the drive link into the dictionary
  }
  const handleBenchmarkingFileUpload = async (e) => {
    // Need to check if we need to create the folder
    // Upload the file
    // Send the link back to handleChange to save it to the dictionary
    handleChange(e, "file attached");
  }

  let questionText = question.question;
  if (question.required === true) {
    questionText = questionText + "*"
  }
  let inputTag;
  if (question.inputType === "text" || question.inputType === "email" || question.inputType === "currency" || question.inputType === "number" || question.inputType === "date") {
    inputTag = (
      <input
        class="form-control"
        type={question.inputType}
        id={id}
        name={question.salesforceField}
        placeholder={question.exampleText}
        defaultValue={question.inputValue}
        onChange={handleChange} 
      />
    )
  } else if (question.inputType === "textarea") {
    inputTag = (
      <textarea
        class="form-control"
        id={id}
        name={question.salesforceField}
        placeholder={question.exampleText}
        defaultValue={question.inputValue}
        onChange={handleChange}
        rows="3"
      />
    )
  } else if (question.inputType === 'Single Select') {
    inputTag = (
      <>
        <select 
          class="form-select"
          id={id}
          name={question.salesforceField}
          onChange={handleChange}
        >
          <option value="" selected={true} disabled="disabled">Select</option>
          {
            question.inputOptions.map(inputOption => {
              const isSelected = question.inputValue === inputOption;
              return (
                <option value={inputOption} selected={isSelected}>{inputOption}</option>
              )
            })
          }
        </select>
      </>
    )
  } else if (question.inputType === 'Multi Select') {
    inputTag = (
      <>
        <select
          multiple
          class="form-control"
          id={id}
          name={question.salesforceField}
          onChange={handleChange}
        >
          <option value="" disabled="disabled">Can Select Multiple</option>
          {
            question.inputOptions.map(inputOption => {
              const isSelected = question.inputValue.includes(inputOption);
              return (
                <option value={inputOption} selected={isSelected}>{inputOption}</option>
              )
            })
          }
        </select>
      </>
    )
  } else if (question.inputType === "Google Drive Link") {
    inputTag = (
      <>
        <div className='file-input-div'>  
          <form id={`form-${id}`} onChange={handleFileUpload}>
            <input type="file" name="files" className='form-control'/>
          </form>
          {/* <p className='upload-status'>
              {statusIcon}
              {uploadStatus}
            </p> */}
        </div>
      </>
    )
  } else if (question.inputType === "Benchmarking Google Drive Link") {
    inputTag = (
      <>
        <div className='file-input-div'>  
          <form id={`form-${id}`} onChange={handleBenchmarkingFileUpload}>
            <input type="file" name="files" className='form-control'/>
          </form>
          {/* <p className='upload-status'>
              {statusIcon}
              {uploadStatus}
            </p> */}
        </div>
      </>
    )
  } 
  let toolTip = <></>;
  if (question.infoText !== "") {
    toolTip = <Tooltip content={question.infoText}>
      <button className="info-button">i</button>
    </Tooltip>;
  }
  
  return (
    <>
      <div className="form-group row mb-3">
        <label for={question.salesforceField} class="col-sm-4 col-form-label">{questionText}
          {toolTip}
        </label>

        <div class="col-sm-8">
          {inputTag}
        </div>
      </div>
    </>
  )
}


  
  export default QuestionItem;
import splash from '../../assets/splash.png';
import React from 'react';
import { Link } from 'react-router-dom';


function MainPage() {

  return (
    <>
      <div className="formEnterPage">
        <div className='formEnter'>
          <div className="formEnterBody">
            <div className="formEnterText">
              <h1>Join the Revere Community</h1>
              <p>We founded Revere with the simple mission of solving the pain points concerning investing in early-stage emerging fund managers.
                We address the root cause of the pain points, not simply the symptoms. 
                Thus, we promote information and data transparency through a standard 
                institutional diligence framework for evaluating and rating managers.
                </p>
              <p><Link to="/benchmarking" style={{color: 'inherit', textDecoration: 'underline'}}>2023 Benchmarking Survey</Link></p>
              <p><Link to="/docgather" style={{color: 'inherit', textDecoration: 'underline'}}>Information and Document Gather Form</Link></p>
            </div>
            <div className="formEnterImg">
              <img draggable="false" src={splash} id='splash-img'/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default MainPage;

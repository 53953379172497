import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';

// some change
class FirebaseConnect {
    app;
    functions;

    constructor(app) {
        this.app = app;
        this.functions = getFunctions(app);

    }

    static initInstance() {
        this.app = initializeApp({
            apiKey: "AIzaSyCj5bidoeuU5YATTRlPGaJbBTTWvdiz6jU",
            authDomain: "pipeline-tool-playground.firebaseapp.com",
            projectId: "pipeline-tool-playground",
            storageBucket: "pipeline-tool-playground.appspot.com",
            messagingSenderId: "296097737495",
            appId: "1:296097737495:web:e6dbadac0af0cde2602552"
        });
        this.functions = getFunctions(this.app, "us-central1");
    }


}

export default FirebaseConnect;
import logo from '../../assets/logo.png';
import './NavBar.css'
import { Link } from 'react-router-dom';


function NavBar({updatePage}) {
  return (
    <div className="nav-bar">
      <a href="https://www.reverevc.com/" target="_blank">
        <img src={logo} id='logo-img'/>
      </a>
      {/* <Link to="docgather">Doc Gather</Link> */}
    </div>
  )
}

export default NavBar;